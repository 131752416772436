.cartDropdownContainer {
  position: absolute;
  width: 240px;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid black;
  background-color: white;
  top: 60px;
  right: 20px;
  z-index:15;

  .emptyMessage {
    font-size: 18px;
    margin: 50px auto;
  }

  .cartItems {
    height: 240px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  button {
    margin-top: auto;
  }
}
