$sub-color: grey;
$main-color: black;

@mixin shrinkLabel {
  top: -0.7vw;
  font-size: 1.5vw;
  color: $main-color;
}

.group {
  position: relative;
  margin: 2vw 0;

  .formInput {
    background: none;
    background-color: white;
    color: $sub-color;
    font-size: 1.8vw;
    padding: 1vw 1vw 0.2vw 0.2vw;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 0.5vw 0;

    &:focus {
      outline: none;
    }

    &:focus ~ .formInputLabel {
      @include shrinkLabel();
    }
  }

  input[type='password'] {
    letter-spacing: 0.3vw;
  }

  .formInputLabel {
    color: $sub-color;
    font-size: 1.5vw;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.2vw;
    top: 1vw;
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}
