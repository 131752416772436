.checkoutContainer {
  position: relative;
  width: 75%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto 0;
  font-size: 2vw;

  .checkoutHeader {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    .headerBlock {
      text-align: center;
      text-transform: capitalize;
      width: 23%;

      &:lastchild {
        width: 8%;
      }
    }
  }

  .footer {
    position: relative;
    width: 100%;
    height: 10vh;
    margin-bottom: 20px;
    font-size: 2vw;
    font-weight: bold;

    .total {
      position: absolute;
      bottom: 0px;
      right: 10px;
    }

    .itemsCount {
      position: absolute;
      bottom: 0px;
      right: 44%;
    }
  }
}
