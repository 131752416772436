.signInContainer {
    display: flex;
    flex-direction: column;
    width: 40vw;
    padding: 0 2vw;

    h2 {
        margin: 0.5vw 0;
    }

    .buttonsContainer {
      display: flex;
      justify-content: space-between;  
    }
}