.shopCategory {
  position: relative;
  margin: 1vw 2vw;
  font-size: 2vw;
  font-weight: bold;

  .arrowLeft {
    position: absolute;
    top: 40%;
    left: -2.7vw;
    font-size: 5vw;
    color: black;
    opacity: 0.5;
    z-index: 10;
    cursor: pointer;
  }

  .arrowRight {
    position: absolute;
    top: 40%;
    right: -2.7vw;
    font-size: 5vw;
    color: black;
    opacity: 0.5;
    z-index: 10;
    cursor: pointer;
  }

  .arrow {
    font-size: 2.5vw;
    cursor: pointer;
  }

  .shopContainer {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: auto;
    grid-auto-flow: column;
    overflow-x: scroll;
    column-gap: 0.5vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 0;
  }

  .shopContainer::-webkit-scrollbar {
    display: none;
  }
}
