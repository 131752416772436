.shopCategoryContainer {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-left: 5vw;

  .shopCategoryItemsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 1vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: 2vw;
  }

  .shopCategoryItemsContainer::-webkit-scrollbar {
    display: none;
  }

  .shopCategoryContainer::-webkit-scrollbar {
    display: none;
  }

  .title {
    font-size: 3vw;
    font-weight: bold;
    padding-left:  40vw;
  }
}
