.navigation {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  
    .logoContainer {
      position: relative;
      width: 50%;
      height: auto;
      padding: 1vw 1vw;

      .logo {
        width: 50px;
        height: 50px;
      }

      .back {
        position: absolute;
        bottom: 10px;
        left: 80px;
        font-size: 30px;
        font-weight: bolder;
        cursor: pointer;
      }

      .forward {
        position: absolute;
        bottom: 10px;
        left: 110px;
        font-size: 30px;
        font-weight: bolder;
        cursor: pointer;
      }

    }
  
    .navLinksContainer {
      width: 50%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
  
      .navLink {
        padding: 1vw 2vw;
        cursor: pointer;
        font-size: 15px;
        font-weight: bold;
      }
    }
  }